import React from 'react';
import { Layout, SEO } from 'components/common';
import { Document } from 'components/landing';

export default () => (
    <Layout>
        <SEO title="404: Not found" location="/document" />
        <h1>Декларация о соответствии</h1>
        <Document />
    </Layout>
  );
